export default {
  links: [
    // change to int
    {
      title: 'Mentions légales',
      link: '',
      type: 'external'
    },
    {
      title: 'Politique de confidentialité',
      link: 'Privacy',
      type: 'internal'
    },
    {
      title: 'Cookies',
      link: '',
      type: 'external'
    },
    {
      title: 'Crédits',
      link: '',
      type: 'external'
    },
    {
      title: 'Contact',
      link: '',
      type: 'external'
    },
  ],
};
